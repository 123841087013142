import {Component} from '@angular/core';
import {NavController, NavParams, ModalController} from '@ionic/angular';
import {CartStore} from '../../state/cart-store';
import {CheckoutModalPage} from '../checkout-modal/checkout-modal';

/**
 * Generated class for the ContinueShoppingModalPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

declare var dataLayer: any[];

@Component({
    selector: 'page-continue-shopping-modal',
    templateUrl: 'continue-shopping-modal.html',
    styleUrls: ['./continue-shopping-modal.scss']
})
export class ContinueShoppingModalPage {
    private navToHome = false;
    homeParams: any = null;

    constructor(public navCtrl: NavController,
                public navParams: NavParams,
                private modalCtrl: ModalController,
                private cartStore: CartStore, ) {
        this.homeParams = this.navParams.get('homeParams');
        if (this.homeParams) {
            this.navToHome = true;
        }
    }

    ionViewDidEnter() {
        dataLayer.push({
            'event': 'Pageview',
            'screenName': 'ContinueShoppingModal'
        });
    }

    ionViewDidLoad() {
    }

    async viewDismiss() {
        // this.viewCtrl.dismiss();
        await this.modalCtrl.dismiss();
    }

    continueShopping() {
        if (this.navToHome) {
            this.viewDismiss();
            // this.navCtrl.setRoot('HomePage', this.homeParams);
        } else {
            this.viewDismiss();
        }
    }

    async initiateCheckout() {

        if (this.homeParams.accountType) {
            this.navCtrl.navigateForward(`checkout/${this.homeParams.accountType}/${this.homeParams.accountID}/${this.homeParams.secondaryID}`);
            this.viewDismiss();
        } else {
            // this.viewCtrl.dismiss();
            this.viewDismiss();

            // const modal = await this.modalCtrl.create({
            //     component: CheckoutModalPage
            // });
            // modal.present();
        }
    }
}
