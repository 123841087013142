import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {ContinueShoppingModalPage} from './continue-shopping-modal';

@NgModule({
    declarations: [
        ContinueShoppingModalPage,
    ],
    imports: [
        IonicModule
    ],
    entryComponents: [ContinueShoppingModalPage],
    exports: [ContinueShoppingModalPage]
})
export class ContinueShoppingModalPageModule {
}
